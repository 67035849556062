<template>
  <div class="row">
    <div v-if="showAddButton" class="col-lg-12 d-flex justify-content-end mb-h">
            <div class="pl-1 ">
          <button class="btn btn-info btn-md" @click="openRadiusWizard" v-if="!isDisable">
            {{ $t('radius.addRadius') }}
          </button>
        </div>
    </div>
    <div class="col-lg-12 mb-1" v-if="showAddButton">
      <div class="p-0 filter-block">
        <div class="w-100">
          <div class="filters filters__radius w-100">
            <div class="col-lg-3 p-0 pr-1">
            <div class=" ">
              <div class=" ">
                <div class="input-group input-group-sm ">
                  <span class="input-group-addon"><i class="fa fa-search"></i></span>
                  <input
                    id="search"
                    name="search"
                    :placeholder="$t('general.searchPlaceHolder')"
                    class="form-control"
                    v-model="filters.query"
                  />
                  <span class="clear-input text-muted" v-if="filters.query != ''" @click="filters.query = ''">
                    <i class="fa fa-remove"></i>
                  </span>
                </div>
              </div>
            </div>
            </div>

<!--            <div class="filters__multiple">-->
<!--              <div class="input-group input-group-sm filters__input">-->
<!--                <span class="input-group-addon filters__field-icon"><i class="fa fa-map-marker"></i></span>-->
<!--                <select @change="refreshRadiusList" class="form-control form-control-sm filters__field" v-model="filters.location">-->
<!--                  <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>-->
<!--                  <option v-for="location in locationsList" :value="location.id" :key="location.id">-->
<!--                    {{ location.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--                        <div-->
<!--                class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">-->
<!--                <div-->
<!--                  class="  d-flex align-items-end justify-content-start"-->
<!--                >-->
<!--                  <Switch-component-->
<!--                    :disabled="this.filters.location === 'All locations'"-->
<!--                    v-model="filters.with_childs"-->
<!--                    class="switcher-with-childs-for-use-in-filters"-->
<!--                    @change="refreshRadiusList"-->
<!--                  />-->
<!--                </div>-->
<!--                <div-->
<!--                  class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">-->
<!--                  <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--                </div>-->
<!--              </div>-->
<div class="col-lg-3 p-0">
                                        <div
                  class="input-group-for-location-select-in-radius--filters   ">
                                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-radius--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="refreshRadiusList"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>
                </div>
</div>

          </div>
        </div>
<!--        <div class="pl-1 mb-1">-->
<!--          <button class="btn btn-warning btn-md" @click="openRadiusWizard" v-if="!isDisable">-->
<!--            {{ $t('radius.addRadius') }}-->
<!--          </button>-->
<!--        </div>-->
      </div>
                                  <div v-if="isFiltersActive" class=" mt-h">
                <button
                  class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters"
                  @click="resetAllFilters"
                >
                  <i class="fa fa-close"></i>
                  {{ $t('general.resetAllFilters') }}
                </button>
        </div>
      <!-- <div class="btn-group btn-group-sm float-left" data-toggle="button">
                <label class="btn btn btn-outline-warning" :class="{'active': filters.typeFilter == 'acct'}">
                    <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'acct')">Acct
                </label>
                <label class="btn btn btn-outline-warning" :class="{'active': filters.typeFilter == 'auth'}">
                    <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'auth')">Auth
                </label>
                <label class="btn btn btn-outline-warning" :class="{'active': filters.typeFilter == 'both'}">
                    <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'both')">Both
                </label>
            </div> -->
    </div>
    <div class="col-lg-12" v-if="showAddButton">
      <div class="radius" :class="{ 'edit-visible': editVisible }">
        <div class="radius-list animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <!--                  <div class="loader loader-backdrop loader-backdrop-radius" v-if="loadingRadius"></div>-->
                  <div v-if="loadingRadius"><Loader-spinner /></div>
                  <div class="card-header">
                    <div>
                      <i class="fa fa-align-justify"></i>
                      {{ $t('radius.radiusList') }}
                    </div>
                  </div>
                  <div class="card-block">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>{{ $t('general.name') }}</th>
                          <th>{{ $t('radius.addressInTableTitle') }}</th>
<!--                          <th>{{ $t('radius.secret') }}</th>-->
                          <th>{{ $t('radius.password') }}</th>
                          <th>{{ $t('radius.authPort') }}</th>
                          <th>{{ $t('radius.acctPort') }}</th>
                          <th>{{ $t('general.location') }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr v-if="this.filteredRadius.length == 0">
                                                    <td class="radius-row-nodata" colspan="7"> No results found </td>
                                                </tr> -->
                        <tr
                          v-for="(radius, index) in filterItems(radiusList)"
                          :data-index="index"
                          @click="toggleEdit"
                          class="radius-row"
                          :data-id="radius.id"
                          :class="{
                            selected: index === radiusSelectedIndex,
                            'radius-loading': radiusSpinner(radius.id)
                          }"
                        >
                          <td class="responsive-row">{{ radius.name }}</td>
                          <td class="responsive-row">{{ radius.hostname }}</td>
                          <td class="responsive-row">{{ radius.secret }}</td>
                          <td class="responsive-row">{{ radius.auth_port }}</td>
                          <td class="responsive-row">{{ radius.acc_port }}</td>
                          <td class="responsive-row">{{ locationName(radius.base_location) }}</td>
                          <td class="action-row" v-if="!editVisible && !isDisable">
                            <!-- <i class="fa fa-pencil fa-lg mr-q text-info user-action" data-action="edit"></i> -->
                            <i
                              v-if="!isDisable"
                              class="fa fa-remove fa-lg mr-q text-danger user-action"
                              data-action="delete"
                              @click="openDeletingDialog(radius.id)"
                            ></i>
                          </td>
                        </tr>
                        <tr v-if="!filterItems(radiusList).length">
                          <td colspan="7" class="no-data-row">
                            <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="radius-edit" v-if="editVisible">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="edit-header">
                        <div class="edit-header__info">
                          <div class="edit-header__name">
                            <span class="h4">{{ radiusEditCurrent.name }}</span>
                          </div>
                          <div class="edit-header__tags"></div>
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm float-right edit-btn-close new-modern-style-btn-close-panel"
                            @click="disableEditMode"
                          >
                            <i class="fa fa-close"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-block pt-0">
                  <small class="text-muted" v-if="!isDisable">{{ $t('general.editingMode') }}</small>
                  <small class="text-muted" v-if="isDisable">{{ $t('general.readOnlyMode') }}</small>
                  <div class="form-group mt-1">
                    <label for="ip address">
                      {{ $t('radius.addressField') }}
                      <span class="text-muted small">({{ $t('radius.addressFieldHint') }})</span>
                    </label>
                    <input
                      :data-vv-as="$t('radius.addressField')"
                      type="text"
                      name="ip address"
                      id="ip address"
                      :placeholder="$t('radius.ipPlaceholder')"
                      class="form-control"
                      v-validate="'required|ipv4orDomainName'"
                      :class="{ input: true, 'is-danger': errors.has('ip address') }"
                      v-model="updatedRadius.hostname"
                      :disabled="isDisable"
                    />
                    <span v-show="errors.has('ip address')" class="help is-danger">
                      {{ errors.first('ip address') }}
                    </span>
                  </div>

                  <div class="form-group">
                    <label for="name">{{ $t('general.name') }}</label>
                    <input
                      :data-vv-as="$t('general.name')"
                      type="text"
                      name="name"
                      id="name"
                      :placeholder="$t('radius.namePlaceholder')"
                      class="form-control"
                      v-validate="'required|max:32'"
                      :class="{ input: true, 'is-danger': errors.has('name') }"
                      v-model="updatedRadius.name"
                      :disabled="isDisable"
                    />
                    <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
                  </div>

                  <div class="form-group">
<!--                    <label for="secret">{{ $t('radius.secret') }}</label>-->
                    <label for="secret">{{ $t('radius.password') }}</label>
                    <input
                      :data-vv-as="$t('radius.password')"
                      type="text"
                      name="secret"
                      id="secret"
                      :placeholder="$t('radius.secretPlaceholder')"
                      class="form-control"
                      v-validate="'required|max:32'"
                      :class="{ input: true, 'is-danger': errors.has('secret') }"
                      v-model="updatedRadius.secret"
                      :disabled="isDisable"
                    />
                    <span v-show="errors.has('secret')" class="help is-danger">{{ errors.first('secret') }}</span>
                  </div>

                  <div class="form-group">
                    <label for="auth port">{{ $t('radius.authPort') }}</label>
                    <input
                      :data-vv-as="$t('radius.authPort')"
                      type="text"
                      name="auth_port"
                      id="auth port"
                      :placeholder="$t('radius.authPortPlaceholder')"
                      class="form-control"
                      v-validate="'required|numeric|between:1,65535'"
                      :class="{ input: true, 'is-danger': errors.has('auth_port') }"
                      v-model="updatedRadius.auth_port"
                      :disabled="isDisable"
                    />
                    <span v-show="errors.has('auth_port')" class="help is-danger">{{ errors.first('auth_port') }}</span>
                  </div>

                  <div class="form-group">
                    <label for="acct port">{{ $t('radius.acctPort') }}</label>
                    <input
                      :data-vv-as="$t('radius.acctPort')"
                      type="text"
                      name="acc_port"
                      id="acct port"
                      :placeholder="$t('radius.acctPortPlaceholder')"
                      class="form-control"
                      v-validate="'required|numeric|between:1,65535'"
                      :class="{ input: true, 'is-danger': errors.has('acc_port') }"
                      v-model="updatedRadius.acc_port"
                      :disabled="isDisable"
                    />
                    <span v-show="errors.has('acc_port')" class="help is-danger">{{ errors.first('acc_port') }}</span>
                  </div>

                  <div class="form-group">
                    <label for="location">{{ $t('general.location') }}</label>
                    <select
                      v-model="updatedRadius.base_location"
                      id="location"
                      name="location"
                      class="form-control"
                      :class="{ 'select-disabled': isDisable }"
                      :disabled="isDisable"
                    >
                      <option v-for="location in locationsList" :key="location.id" :value="location.id">
                        {{ location.name }}
                      </option>
                    </select>
                  </div>

                  <h6 class="mt-3">{{ $t('radius.daeSettings') }}</h6>
                  <!--                  dae_client-->
                  <div class="form-group mt-1">
                    <label for="dae_client">
                      {{ $t('radius.daeClient') }}
                      <info :content="$t('radius.daeClientHintInfoMsg')" />
                    </label>
                    <input
                      :data-vv-as="$t('radius.daeClient')"
                      type="text"
                      name="dae_client"
                      id="dae_client"
                      :placeholder="$t('radius.daeClientPlaceholder')"
                      class="form-control"
                      v-validate="'radiusDaeClient'"
                      :class="{ input: true, 'is-danger': errors.has('dae_client') }"
                      v-model="updatedRadius.dae_client"
                      :disabled="isDisable"
                      data-vv-validate-on="change"
                      @input="daeClientInputHandler"
                    />
                    <span v-show="errors.has('dae_client')" class="help is-danger">
                      {{ errors.first('dae_client') }}
                    </span>
                  </div>
                  <!--                  dae_port-->
                  <div class="form-group mt-1">
                    <label for="dae_client">
                      {{ $t('radius.daePort') }}
                      <info :content="$t('radius.daePortHintInfoMsg')" />
                    </label>
                    <input
                      :data-vv-as="$t('radius.daePort')"
                      type="text"
                      name="dae_port"
                      id="dae_port"
                      :placeholder="$t('radius.daePortPlaceholder')"
                      class="form-control"
                      v-validate="'numeric|between:1,65535'"
                      :class="{ input: true, 'is-danger': errors.has('dae_port') }"
                      v-model="updatedRadius.dae_port"
                      :disabled="isDisable"
                      data-vv-validate-on="input"
                    />
                    <span v-show="errors.has('dae_port')" class="help is-danger">{{ errors.first('dae_port') }}</span>
                  </div>

                  <!--                  dae_secret-->
                  <div class="form-group mt-1">
                    <label for="dae_client">
                      {{ $t('radius.daeSecret') }}
                      <info :content="$t('radius.daeSecretHintInfoMsg')" />
                    </label>
                    <textarea
                      name="dae_secret"
                      id="dae_secret"
                      :placeholder="$t('radius.daeSecretPlaceholder')"
                      class="form-control"
                      :class="{ input: true, 'is-danger': errors.has('dae_secret') }"
                      v-model="updatedRadius.dae_secret"
                      :disabled="isDisable"
                    />
                    <span v-show="errors.has('dae_secret')" class="help is-danger">
                      {{ errors.first('dae_secret') }}
                    </span>
                  </div>

                  <div class="form-group mt-3">
                    <Switch-component
                      v-model="updatedRadius.is_local"
                      :disabled="isDisable"
                      :label="$t('radius.local')"
                      @change="updatedRadius.is_portal = false"
                      id="radius-local"
                    />
                  </div>
                  <div class="form-group">
                    <Switch-component
                      v-model="updatedRadius.is_portal"
                      :disabled="isDisable || updatedRadius.is_local"
                      :label="$t('radius.portal')"
                      id="radius-portal"
                    />
                  </div>
                </div>
                <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
                  <div>
                    <button
                      v-if="!isDisable"
                      type="button"
                      :disabled="errors.any() || !enableSaveChanges"
                      class="btn btn-outline-success btn-md mr-1"
                      @click="updateRadius"
                    >
                      <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </button>
                    <button
                      v-if="!isDisable"
                      type="button"
                      :disabled="errors.any()"
                      class="btn btn-outline-info btn-md"
                      @click="copyRaduis"
                    >
                      <span :class="{ invisible: muteEdit }">{{ $t('general.copy') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </button>
                  </div>
                  <div>
                    <button
                      v-if="!isDisable"
                      :class="{ invisible: muteEdit }"
                      type="button"
                      class="btn btn-outline-danger btn-md mr-1"
                      @click="openDeletingDialog(updatedRadius.id)"
                    >
                      <span :class="{ invisible: muteEdit }">{{ $t('general.delete') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </button>
                    <button
                      :class="{ invisible: muteEdit }"
                      type="button"
                      class="btn btn-outline-secondary btn-md"
                      @click="disableEditMode"
                    >
                      <span v-if="isDisable">{{ $t('general.close') }}</span>
                      <span v-else>{{ $t('general.cancel') }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12"><router-view></router-view></div>
    <modal title="Delete RADIUS" v-model="deletingRadiusModal" class="modal-danger" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('radius.deleteRADIUSModalTitle') }}</h4>
      {{ $t('radius.confirmDeletingModal') }}
      <b>{{ radiusName(radiusIdToDelete) }}</b>
      ?
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="cancelDeleting">{{ $t('general.cancel') }}</button>
        <button type="button" class="btn btn-outline-danger" @click="deleteRadius">
          {{ $t('general.yesDelete') }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import VueNotifications from 'vue-notifications';
import Modal from '../../components/Modal.vue';
import radiusService from '../../services/radiusService';
import commonService from '../../services/commonService';
import radiusPollingService from '../../services/radiusPollingService';
import Filters from '../../filters';
import locationService from '../../services/locationService';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import helpers from '../../helpers';
import Info from '../../components/Universal/info-icon.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

export default {
  name: 'Radius',
  components: { Modal, SwitchComponent, Info, SelectComponentV2, SelectComponentV3ForUseInLocationSelectors },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      isEditVisible: false,
      updatedRadius: {},
      deletingRadiusModal: false,
      radiusIdToDelete: null,
      enableSaveChanges: false,

      filters: {
        query: '',
        typeFilter: '',
        location: 'All locations',
        with_childs: false,
      },
      filteredRadius: []
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    showAddButton() {
      return this.$route.name === 'Radius';
    },
    editVisible() {
      return typeof this.$store.state.editRadiusIndex === 'string' && this.$store.state.editRadiusIndex.length;
    },
    muteEdit() {
      return this.$store.state.muteRadiusEdit;
    },
    radiusList() {
      return this.$store.state.radiusList;
    },
    radiusOperations() {
      return this.$store.state.radiusOperations;
    },
    loadingRadius() {
      return this.$store.state.loadingRadius;
    },
    radiusEditCurrent() {
      this.editInited = false;
      const { radiusList } = this.$store.state;
      let currentRadius;
      radiusList.forEach((radius) => {
        if (radius.id == this.$store.state.editRadiusId) {
          currentRadius = radius;
        }
      });

      this.updatedRadius = { ...currentRadius };
      this.editInited = true;
      return currentRadius;
    },
    radiusEditCurrentId() {
      return this.$store.state.radiusList[this.$store.state.editRadiusIndex].id;
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    isFiltersActive() {
      return (
        this.filters.location !== 'All locations' ||
        this.filters.query !== '' || this.filters.typeFilter !== ''
      );
    },
    radiusSelectedIndex() {
      return this.$store.state.editRadiusIndex;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    userDataLocation() {
      // this.filters.location = this.$store.state.userData.location;
      return this.$store.state.userData.location;
    },
    isNoData() {
      return this.filteredRadius.length === 0;
    }
  },
  watch: {
    radiusList() {
      radiusPollingService.startPolling(this);
    },
    radiusOperations() {
      this.radiusSpinner();
    },
    updatedRadius: {
      handler(val, oldVal) {
        if (!oldVal) {
          this.enableSaveChanges = false;
        } else if (val && oldVal && val.id !== oldVal.id) {
          this.enableSaveChanges = false;
        } else if (!val) {
          this.enableSaveChanges = false;
        } else {
          this.enableSaveChanges = true;
        }
      },
      deep: true
    }
  },
  methods: {
    daeClientInputHandler(e) {
      // очищаем ошибку dae_client, если она есть, каждый раз когда начанаем вводить dae_client
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('dae_client')) {
          // console.log('has dae_client error');
          this.errors.remove('dae_client');
        }
      }
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshRadiusList()

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleEdit(e) {
      if (e.target.dataset.action === 'delete') {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { id } = selectedNode.dataset;

      this.$store.commit('setRadiusEditIndex', index);
      this.$store.commit('setRadiusEditId', id);
    },
    disableEditMode() {
      this.$store.commit('setRadiusEditIndex', null);
    },
    // resetAllFilters() {
    //   this.disableEditMode();
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters = {
    //     query: '',
    //     typeFilter: '',
    //     location: 'All locations',
    //     with_childs: false
    //   };
    //   this.refreshRadiusList();
    // },
    resetAllFilters() {
      this.disableEditMode();
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters = {
        query: '',
        typeFilter: '',
        // location: 'All locations',
        // with_childs: false
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.refreshRadiusList();
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    updateRadius() {
      if (this.updatedRadius.dae_port === '') {
        // если ничего не ввели ставим в значение по умолчанию
        this.updatedRadius.dae_port = '3799';
      }
      this.$validator.validateAll({}).then(
        (result) => {
          if (result) {
            radiusService.updateRadius(this);
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    copyRaduis() {
      const copy = { ...this.updatedRadius };

      this.axios.post(`${window.RESTAPI || ''}/api/radius`, { action: 'C', items: { 0: copy } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          this.$store.commit('clearNewRadiusData');
          radiusPollingService.hadleOperationResponse(response.data.data.items);
          radiusService.getRadius(this, true);
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
    },
    openRadiusWizard() {
      this.$router.push({ name: 'radius-wizard' });
    },
    openDeletingDialog(id) {
      this.radiusIdToDelete = id;
      this.deletingRadiusModal = true;
    },
    deleteRadius() {
      radiusService.deleteRadius(this);
      this.radiusIdToDelete = '';
    },
    cancelDeleting() {
      this.deletingRadiusModal = false;
      this.radiusIdToDelete = '';
    },
    refreshRadiusList() {
      radiusService.getRadius(this, true);
    },
    radiusName(id) {
      const radiusName = commonService.radiusNamebyId(id);
      return radiusName;
    },
    // filterItems(items) {
    //   let filteredRadius = items;
    //
    //   filteredRadius = Filters.radiusSearchQuery(filteredRadius, this.filters.query);
    //   filteredRadius = Filters.radiusTypeFilters(filteredRadius, this.filters.typeFilter);
    //   filteredRadius = Filters.locationFilter(filteredRadius, this.filters.location);
    //   this.filteredRadius = filteredRadius;
    //   return filteredRadius;
    // },
    filterItems(items) {
      let filteredRadius = items;

      filteredRadius = Filters.radiusSearchQuery(filteredRadius, this.filters.query);
      filteredRadius = Filters.radiusTypeFilters(filteredRadius, this.filters.typeFilter);
      // filteredRadius = Filters.locationFilter(filteredRadius, this.filters.location);
      this.filteredRadius = filteredRadius;
      return filteredRadius;
    },
    setFilter(filterName, value) {
      this.disableEditMode();
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
        return;
      }
      this.filters[filterName] = '';
    },
    radiusSpinner(forceId) {
      const { radiusOperations } = this.$store.state;
      const radiusRows = document.querySelectorAll('tr');

      radiusRows.forEach((radiusRow) => {
        radiusRow.classList.remove('radius-loading');
      });

      if (forceId) {
        radiusOperations.forEach((operation) => {
          if (operation.model_id === forceId) {
            setTimeout(() => {
              const radiusRow = document.querySelector(`[data-id="${forceId}"]`);
              if (radiusRow) {
                radiusRow.classList.add('radius-loading');
              }
            }, 1);
          }
        });
      } else if (radiusOperations.length === 0) {
      } else {
        radiusOperations.forEach((operation) => {
          radiusRows.forEach((radiusRow) => {
            if (radiusRow.dataset.id === operation.model_id) {
              radiusRow.classList.add('radius-loading');
            }
          });
        });
      }
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    }
  },
  // created() {
  //   radiusService.getRadius(this);
  //   locationService.getLocations(this);
  //   this.$store.state.editRadiusIndex = '';
  // },
  created() {
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    radiusService.getRadius(this, true);
    locationService.getLocations(this);
    this.$store.state.editRadiusIndex = '';
  }
};
</script>
<style lang="scss">

      .input-group-for-location-select-in-radius--filters {

    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }
      .select-location-in-radius--filters .select__list-container {
    width: 400px;
  }
.radius {
  display: flex;
}

.radius-list,
.radius-edit {
  transition: width 0.2s;
}

.radius-list {
  display: inline-block;
  width: 100%;
}

.radius-edit {
  display: inline-block;
  width: 0%;
  word-wrap: break-word;
}

.radius-edit .card-header {
  border: 0;
}

.edit-visible .radius-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 60%;
}

.edit-visible .radius-edit {
  transition: width 0.2s;
  width: 40%;
  min-width: 260px;
}

.radius-row {
  transition: background 0.15s;
  cursor: pointer;
}

.radius-row.selected {
  background: #d9e3ec;
}

.radius-row:hover {
  background: rgba(236, 236, 236, 0.59);
}
.radius-row-nodata {
  text-align: center;
}

/*Style for filters*/
.btn.btn-outline-warning:hover {
  background-color: #fad840;
}
.btn.btn-outline-warning.active {
  background-color: #f8cb00;
}

.radius-loading {
  animation-name: blink;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.edit-btn-close {
  line-height: 14px;
  font-size: 14px !important;
  height: 27.5px;
  width: 29px;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.loader-backdrop-radius:after {
  position: absolute;
  top: 30px;
}
</style>
